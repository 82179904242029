html {
  scroll-behavior: smooth;
}

.text-outline {
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: black;
}

.bg-story {
  background-image: url(./assets/bg-story.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.border-3 {
  border-width: 3px;
}

.card-shadow {
    box-shadow: 9px 8.62px #000000;
}

.shadow-buy {
  box-shadow: 5px 4.62px #000000;
}

.animate-beat {
  animation: 2s ease infinite beat;
}

 @keyframes beat {
  0%, 50%, 100% { transform: scale(1, 1); }
  30%, 80% { transform: scale(0.92, 0.95) ; }
}

.animate-wiggle:hover {
  -webkit-animation-name: shake;
  -ms-animation-name: shake;
  -ms-animation-duration: 1000ms;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
}

@keyframes shake {
  0% {-webkit-transform: rotate(5deg);}
  25% {-webkit-transform: rotate(-5deg);}
  50% {-webkit-transform: rotate(10deg);}
  100% {-webkit-transform: rotate(0deg);}
}
 

/* ---Sidebar css */

.sidebar {
  width: 250px;
  height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  background: #feaa02;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 0.5s;
  z-index: 50 !important;
}

.sidebar.active {
  left: 0;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.sd-body {
  padding: 10px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}

.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.sd-body ul li {
  list-style: none;
  margin-bottom: 10px;
  width: 100%;
}